<template>
    <div class="favorite">
        <div class="favorite__wrapper container container--small" id="productsList">
            <div class="favorite__header">
                <h1 class="title title--01">Favorite products</h1>
            </div>
            <EmptyState v-if="!favoriteProducts?.length && !is_loading" icon="fileIcon" text_button="Go to Shop" text="No products" @onClick="$router.push('/shop')" />
            <Preloader :show="is_loading" :is_static="true" />
            <div class="favorite__list" v-if="favoriteProducts?.length && !is_loading">
                <ProductCard
                    v-for="product in favoriteProducts"
                    :key="product.id"
                    v-bind="product"
                    :currency="storeSettings?.currency"
                    :is_in_favorite="true"
                    :is_available="product.is_available"
                    :is_not_active="Boolean(!product.is_available && product.is_deleted)"
                    :is_disabled="!product.in_stock"
                    :label="product.type === 'variable' && !product.is_has_editable_text_area ? 'Select options' : product.is_has_editable_text_area ? 'Personalise' : ''"
                    @onAddToCart="onAddToCart(product)"
                    @onFavoriteClick="onRemove(product)"
                />
            </div>
        </div>
    </div>
</template>
<script src="./FavoriteView.js" />
<style src="./FavoriteView.scss" lang="scss" scoped />
