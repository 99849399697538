const setupIntentUrl = `${process.env.VUE_APP_API_HOST_NAME}/api/website/checkout/stripe-setup-intent`;
const orderUrl = `${process.env.VUE_APP_API_HOST_NAME}/api/website/checkout/create-order`;

export default class CheckoutService {
    async setupIntent() {
        const url = setupIntentUrl;
        const urlParams = process.env.VUE_APP_TEST_HOST_NAME ? "https://mariana-dev-usd.mymerchr.com/" : window.location.origin;
        try {
            return fetch(`${url}?url=${urlParams}`).then(res => res.json());
        } catch (err) {
            console.error(err);
            return new Error(err);
        }
    }

    async createOrder(body) {
        try {
            return await fetch(orderUrl, { method: "POST", body: JSON.stringify(body) }).then(res => res.json());
        } catch (err) {
            return err;
        }
    }
}
