import EmptyState from "@/components/shared/emptyState/emptyState.vue";
import BillingDetails from "./components/billingDetails/billingDetails.vue";
import OrdersList from "./components/orders/ordersList.vue";
import Summary from "./components/summary/summary.vue";
import { mapActions, mapGetters } from "vuex";
import CustomButton from "@/components/ui/customButton/customButton.vue";
import CheckoutService from "@/services/checkout.service";
import round from "@/helpers/round_number.js";
import Preloader from "@/components/shared/preloader/preloader.vue";
import OrderStatus from "@/components/shared/orderStatus/orderStatus.vue";

const checkoutService = new CheckoutService();

export default {
    name: "CheckoutView",
    components: { BillingDetails, OrdersList, Summary, CustomButton, Preloader, EmptyState, OrderStatus },
    data: () => ({
        is_deliver_to_different_address: 0,
        customer_note: "",
        billing_details: {
            first_name: "",
            last_name: "",
            company: "",
            billing_street: "",
            billing_apartment: "",
            billing_country: "",
            billing_state: "",
            billing_city: "",
            billing_zip_code: "",
            phone: "",
            email: "",
        },
        shipping_details: {
            shipping_street: "",
            shipping_apartment: "",
            shipping_country: "",
            shipping_city: "",
            shipping_state: "",
            shipping_zip_code: "",
            shipping_first_name: "",
            shipping_last_name: "",
        },
        is_processing: false,
        is_initial_loading: true,
        is_status_popup: false,
        order_status: false,
        request_error: null,
        response_data: null,
        is_ignore_stock_products: false,
        submit_error: false,
    }),
    methods: {
        ...mapActions("products", ["fetchAvailableProducts", "clearProductsInCart"]),
        onBillingDetailsChange(billingDetailsProps) {
            this.billing_details = billingDetailsProps;
        },
        onShippingFieldChange(shippingDetailsProps) {
            this.shipping_details = shippingDetailsProps;
        },
        onDifferentAddressChange(state) {
            this.is_deliver_to_different_address = state;
        },
        onChangeCustomerNote(value) {
            this.customer_note = value;
        },
        validationCallback(fireValidation) {
            this.validation = fireValidation;
        },
        prepareShippingData() {
            const billing_details = this.billing_details;
            const shipping_details = this.shipping_details;

            if (!this.is_deliver_to_different_address) {
                for (const key in billing_details) {
                    if (key.includes("billing")) {
                        shipping_details[key.replace("billing", "shipping")] = billing_details[key];
                    }
                }
                shipping_details.shipping_first_name = billing_details.first_name;
                shipping_details.shipping_last_name = billing_details.last_name;
            }

            return {
                ...billing_details,
                ...shipping_details,
                is_deliver_to_different_address: this.is_deliver_to_different_address,
            };
        },
        prepareOrderItems() {
            const order_items = [];

            this.available_products.forEach(item => {
                const product = item.product;
                order_items.push({
                    option_values: product.product_variant_values ? product.product_variant_values : null,
                    quantity: item.count,
                    price: round(product.selling_price),
                    sku: product.sku,
                    store_product_id: product.id,
                    store_product_variant_id: product.store_product_variant_id ? product.store_product_variant_id : null,
                    total: round(product.selling_price * item.count),
                    meta_data: { ...product.meta_data },
                });
            });
            return order_items;
        },
        async submitPayment() {
            const tax_jar = this.$refs.billingDetails.amount_to_collect;
            const selected_country_is_US =
                (!this.is_deliver_to_different_address && this.$refs.billingDetails.billing_country_code === "US") ||
                (this.is_deliver_to_different_address && this.$refs.billingDetails.shipping_country_code === "US");

            this.is_processing = true;
            const valid = this.validation();
            const card_valid = this.$refs.summary.validatePaymentCard();

            const tax_jar_valid = !(selected_country_is_US && !(tax_jar || tax_jar === 0) && this.store_settings.currency.code === "USD");

            this.submit_error = !(valid && card_valid && tax_jar_valid);
            if (valid && card_valid && this.available_products.length && tax_jar_valid) {
                const setup_intent = await this.$refs.summary.preparePaymentCard();
                const shipping_data = this.prepareShippingData();
                const order_items = this.prepareOrderItems();

                if (!setup_intent) {
                    this.request_error = {
                        button_text: "Try Again",
                        heading: "Your card was declined",
                        description: "Please check your information and try again",
                        on_button_click: () => {
                            this.is_status_popup = false;
                        },
                    };
                    this.order_status = false;
                    this.is_status_popup = true;
                    this.is_processing = false;
                    return;
                }

                const existed_tax_jar = tax_jar || tax_jar === 0 ? { tax_jar } : {};

                const body = {
                    amount: round(this.subtotal_price + this.shipping + tax_jar),
                    customer_note: this.customer_note,
                    payment: { setup_intent },
                    shipping_data,
                    order_items,
                    ...existed_tax_jar,
                };

                const result = await checkoutService.createOrder(body);

                if (result.data?.status === "success") {
                    this.response_data = {
                        ...result.data,
                        amount: this.subtotal_price,
                        // unavailable_products_count: this.unavailable_products_count,
                        available_products_count: this.available_products_count,
                    };
                    this.order_status = true;
                    this.is_status_popup = true;
                } else {
                    if (result.statusCode === 404 && (result.error.description === "items_updated" || result.error.description === "price_updated")) {
                        this.order_status = "warning";
                        this.request_error = {
                            button_text: "Close",
                            heading: "Warning",
                            description:
                                result.error.description === "items_updated"
                                    ? "Some products you previously added to your cart are now out-of-stock and has been removed from order."
                                    : result.error.description === "price_updated"
                                    ? "Since adding products to your order there has been some price changes, please check your order before checking out."
                                    : "Oops something went wrong...",
                            on_button_click: async () => {
                                await this.fetchAvailableProducts();
                                await this.$refs.billingDetails.getTaxJar();
                                this.order_status = false;
                                this.is_status_popup = false;
                            },
                        };
                        this.is_status_popup = true;
                        this.is_processing = false;
                        return;
                    } else {
                        this.request_error = {
                            button_text: "Try Again",
                            heading: result.error.description,
                            description: "Please check your information and try again",
                            on_button_click: () => {
                                this.is_status_popup = false;
                            },
                        };
                        this.order_status = false;
                        this.is_status_popup = true;
                    }
                }
            }
            this.is_processing = false;
        },
        handleClick() {
            if (this.$route.path !== "/shop") {
                this.$router.push("/shop");
            }
        },
        async handlePopupAction() {
            if (this.order_status === true) {
                await this.$router.push("/shop");
                this.clearProductsInCart();
                this.response_data = null;
            } else {
                this.request_error?.on_button_click();
            }
            this.is_status_popup = false;
            this.request_error = null;
        },
        clearData() {
            if (this.response_data) {
                this.clearProductsInCart();
            }
        },
        setupAmount(val) {
            this.$refs.summary.amount_to_collect = val;
            this.$refs.orderList.amount_to_collect = val;
            this.$refs.orderStatus.amount_to_collect = val;
        },
    },
    async mounted() {
        await this.fetchAvailableProducts();
        this.is_initial_loading = false;
        window.addEventListener("beforeunload", this.clearData);
    },
    async unmounted() {
        window.removeEventListener("beforeunload", this.clearData);
    },
    computed: {
        ...mapGetters("products", {
            available_products: "getAvailableProducts",
            subtotal_price: "getAvailableProductsTotalPrice",
            shipping: "getShipping",
            available_products_count: "getAvailableProductsTotalCount",
            unavailable_products_count: "getUnavailableProductsTotalCount",
            shipping_countries: "getShippingCountries",
        }),
        ...mapGetters("storeProps", { store_settings: "getStoreSettings" }),
    },
};
