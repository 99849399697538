import ProductCardHorizontal from "@/components/shared/productCardHorizontal/productCardHorizontal.vue";
import EmptyState from "@/components/shared/emptyState/emptyState";
import { mapGetters } from "vuex";
import parseCost from "@/helpers/parseCost";
import CustomButton from "@/components/ui/customButton/customButton.vue";

export default {
    name: "OrderDetails",
    components: { CustomButton, EmptyState, ProductCardHorizontal },
    props: { order_data: null },
    data: () => ({
        order_description: null,
        products: null,
    }),
    methods: {
        parseCost,
    },
    emits: {
        onClear: null,
    },
    mounted() {
        if (this.order_data) {
            const { country, city, address_1, address_2, postcode, first_name, last_name } = this.order_data?.shipping_meta_data || {};
            const { customer_phone, customer_email } = this.order_data?.customer || {};

            this.order_description = [
                { name: "First Name", value: first_name },
                { name: "Last Name", value: last_name },
                { name: "House number or street name", value: address_1 },
                { name: "Apartment, suite, unit, etc.", value: address_2 },
                { name: "Country", value: country },
                { name: "City", value: city },
                { name: "Postcode", value: postcode },
                { name: "Phone number", value: customer_phone },
                { name: "Email", value: customer_email },
                { name: "Tracking Number", value: this.order_data?.general_tracking, link: this.order_data?.general_tracking_link },
            ];
            this.products = this.order_data.order_items
                .filter(({ order_item_type_id }) => order_item_type_id !== 2)
                .map(product => {
                    const options_description = product.meta_data.options
                        ? Object.entries(product.meta_data.options)
                              .map(([key, value]) => `${key}: ${value}`)
                              .join(", ")
                        : null;
                    const customisation_description = Object.values(product.meta_data.customisation).includes(null)
                        ? null
                        : Object.entries(product.meta_data.customisation)
                              .map(([key, value]) => `${key}: ${value}`)
                              .join(", ");
                    return { ...product, options_description, customisation_description };
                });
        }
    },
    computed: {
        ...mapGetters("storeProps", { store_settings: "getStoreSettings" }),
    },
};
