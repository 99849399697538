<template>
    <div class="order-details">
        <div class="order-details__header">
            <button class="order-details__back" @click="$emit('onClear')">&larr; <span class="order-details__back-text">Back</span></button>
            <div class="title title--01">
                Order Details for <span class="_underline"># {{ order_data.id }}</span>
            </div>
        </div>
        <div class="order-details__holder" v-if="order_data">
            <div class="order-details__description">
                <div class="title title--03">
                    Status: <span class="_underline">{{ order_data.order_status_name }}</span>
                </div>
                <div class="order-details__description-holder">
                    <div class="order-details__row" v-for="{ name, value, link } in order_description" :key="name">
                        <div class="order-details__cell order-details__cell--name">{{ name }}:</div>
                        <div class="order-details__cell">
                            <template v-if="!link">{{ value || "--" }}</template>
                            <a :href="link" target="_blank" v-else>{{ value || "--" }}</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="order-details__products" v-if="products">
                <div class="title title--03">Products in order</div>
                <div class="order-details__products-header">
                    <div class="order-details__products-header-column order-details__products-header-column--wide">Product Description</div>
                    <div class="order-details__products-header-column">Status</div>
                    <div class="order-details__products-header-column">Tracking Number</div>
                    <div class="order-details__products-header-column">Quantity</div>
                    <div class="order-details__products-header-column">Price</div>
                    <div class="order-details__products-header-column">Total</div>
                </div>
                <div class="order-details__products-list">
                    <div class="order-details__card" v-for="(product, index) in products" :key="product.sku + product.id + index">
                        <div class="order-details__card-img">
                            <img :src="product.bought_store_product_img_path" alt="" />
                        </div>
                        <div class="order-details__card-holder">
                            <div class="order-details__card-description">
                                <div class="order-details__card-name">{{ product.store_product_name }}</div>
                                <div class="order-details__card-description-row" v-if="product.options_description">
                                    <span class="order-details__card-column-name">Options:</span>
                                    <span class="order-details__card-column-value">{{ product.options_description }}</span>
                                </div>
                                <div class="order-details__card-description-row" v-if="product.customisation_description">
                                    <span class="order-details__card-column-name">Customisation:</span>
                                    <span class="order-details__card-column-value">{{ product.customisation_description }}</span>
                                </div>
                            </div>
                            <div class="order-details__card-column">
                                <span class="order-details__card-column-name">Status:</span>
                                <span class="order-details__card-column-value">{{ product.order_item_status.name }}</span>
                            </div>
                            <div class="order-details__card-column" v-if="product.shipping_shipment">
                                <span class="order-details__card-column-name">Tracking Number:</span>
                                <span class="order-details__card-column-value"
                                    ><a :href="product.shipping_shipment.shipping_link" target="_blank">{{ product.shipping_shipment.tracking || "--" }}</a></span
                                >
                            </div>
                            <div class="order-details__card-column">
                                <span class="order-details__card-column-name">Quantity:</span>
                                <span class="order-details__card-column-value">{{ product.quantity }}</span>
                            </div>
                            <div class="order-details__card-column">
                                <span class="order-details__card-column-name">Price:</span>
                                <span class="order-details__card-column-value">{{ store_settings.currency.symbol }}{{ parseCost(product.price) }}</span>
                            </div>
                            <div class="order-details__card-column">
                                <span class="order-details__card-column-name">Total:</span>
                                <span class="order-details__card-column-value">{{ store_settings.currency.symbol }}{{ parseCost(product.price * product.quantity) }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="order-details__footer">
                <div class="order-details__footer-heading title title--03">Order total</div>
                <div class="order-details__summary">
                    <div class="order-details__list">
                        <div class="order-details__row">
                            <div class="order-details__cell order-details__cell--name">Subtotal:</div>
                            <div class="order-details__cell">
                                {{ store_settings.currency.symbol }}{{ parseCost(order_data.total - order_data.shipping - (order_data.tax_jar_amount ? order_data.tax_jar_amount : 0)) }}
                            </div>
                        </div>
                        <div class="order-details__row">
                            <div class="order-details__cell order-details__cell--name">Shipping:</div>
                            <div class="order-details__cell">{{ store_settings.currency.symbol }}{{ parseCost(order_data.shipping) }}</div>
                        </div>
                        <div class="order-details__row" v-if="order_data.tax_jar_amount !== null">
                            <div class="order-details__cell order-details__cell--name">Taxes:</div>
                            <div class="order-details__cell">{{ store_settings.currency.symbol }}{{ parseCost(order_data.tax_jar_amount) }}</div>
                        </div>
                        <div class="order-details__row order-details__row--bigger">
                            <div class="order-details__cell order-details__cell--name">Total:</div>
                            <div class="order-details__cell">{{ store_settings.currency.symbol }}{{ parseCost(order_data.total) }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="order-details__bottom btn-holder btn-holder--end">
                <CustomButton @onClick="$emit('onClear')" text="Check another order" />
            </div>
        </div>
    </div>
</template>
<script src="./orderDetails.js" />
<style src="./orderDetails.scss" lang="scss" scoped />
