import { mapActions, mapGetters } from "vuex";
import WebFont from "webfontloader";
import Header from "@/components/shared/header/header.vue";
import Banner from "@/components/shared/banner/banner.vue";
import Footer from "@/components/shared/footer/footer.vue";
import IndexedDbService from "@/services/indexedDB.service";
import { FONTS } from "@/config/fonts.const.js";
import Preloader from "@/components/shared/preloader/preloader.vue";

const indexedDbService = new IndexedDbService();

export default {
    name: "CommonContainer",
    components: {
        Preloader,
        Header,
        Banner,
        Footer,
    },
    data: () => ({
        is_preview_mode: false,
        is_loading: true,
    }),
    methods: {
        ...mapActions("storeProps", ["setProps", "setIsLoadingState", "getStoreProducts"]),
        ...mapActions("products", ["fetchAvailableProducts"]),
        setStoreProps({ styles, themeMode, themeType }) {
            const root = document.querySelector(":root");

            root.classList.add(themeType);
            if (root.classList.contains("stride") && themeType === "venture") {
                root.classList.remove("stride");
            }
            if (root.classList.contains("venture") && themeType === "stride") {
                root.classList.remove("venture");
            }

            root.classList.add(themeMode);
            if (root.classList.contains("dark") && themeMode === "light") {
                root.classList.remove("dark");
            }
            if (root.classList.contains("light") && themeMode === "dark") {
                root.classList.remove("light");
            }

            //root.style.setProperty("color-scheme", this.themeMode);
            Object.entries(styles).forEach(([key, value]) => {
                //console.log(key, value);
                root.style.setProperty(`--${key}`, value);
            });

            this.changeFavicon(this.store_settings.store_favicon_image);
        },
        changeFavicon(url) {
            const links = document.querySelectorAll('link[rel*="icon"], link[rel="apple-touch-icon"]');
            links.forEach((link) => link.parentNode.removeChild(link));

            if (!url || typeof url !== "string") {
                return;
            }
            const extension = url.split(".").pop().toLowerCase();

            const icons = [
                { rel: "icon", type: `"image/${extension}"`, sizes: "16x16", href: url },
                { rel: "icon", type: `"image/${extension}"`, sizes: "32x32", href: url },
                { rel: "icon", type: `"image/${extension}"`, sizes: "48x48", href: url },
                { rel: "icon", type: `"image/${extension}"`, sizes: "64x64", href: url },
                { rel: "icon", type: `"image/${extension}"`, sizes: "96x96", href: url },
                { rel: "apple-touch-icon", href: url },
            ];

            icons.forEach(({ rel, type, sizes, href }) => {
                const link = document.createElement("link");
                link.rel = rel;

                if (extension === "ico") {
                    link.type = "image/x-icon";
                } else {
                    if (type) link.type = type;
                }
                if (sizes) link.sizes = sizes;
                link.href = `${href}?v=${new Date().getTime()}`;
                document.getElementsByTagName("head")[0].appendChild(link);
            });
        },
        checkIfDeviceIsTouch() {
            const isTouch = "ontouchstart" in window || navigator.msMaxTouchPoints > 0;
            if (isTouch && document?.body) {
                document.body.classList.add("is-touch");
            }
        },
        hideBanner() {
            const route = this.$route;
            const not_includes_banner_views = ["ProductPage", "ErrorView", "CartView", "CheckoutView", "AccountView"];
            return (
                route.matched.some((record) => not_includes_banner_views.includes(record.components.default.name)) ||
                !(this.bannerURL || this.store_settings.bannerURL || this.store_settings.store_banner_image)
            );
        },
        async checkPreviewParams() {
            const is_preview_mode = this.$route.query && this.$route.query.is_preview_mode;

            if (is_preview_mode) {
                // prevent click action on any elements when usage previewMode
                document.body.addEventListener(
                    "click",
                    (e) => {
                        e.stopPropagation();
                        e.preventDefault();
                    },
                    true,
                );

                const previewMode = true;
                this.is_preview_mode = true;
                this.setProps({ previewMode });

                window.addEventListener("message", (event) => {
                    const data = event.data;

                    const previewParams = data.params;
                    if (previewParams) {
                        const { themeType, themeMode, logoURL, storeName, styles, bannerURL, aboutText, title, aboutURL, socialLinks, email } = JSON.parse(previewParams);
                        this.setStoreProps({ styles: { ...styles }, themeMode, themeType });
                        //this.setStoreProps({ styles: { ...styles, "pointer-events": "none" }, themeMode, themeType });
                        this.setProps({ themeType, themeMode, logoURL, storeName, bannerURL, aboutText, title, aboutURL, previewMode, socialLinks, email });
                    }
                });
            } else {
                await this.getStoreProducts();
                await this.fetchAvailableProducts();
                this.setStoreProps({ styles: this.themeStyles, themeMode: this.themeMode, themeType: this.themeType });
            }
            this.is_loading = false;
        },
        async loadFonts() {
            await WebFont.load({
                google: {
                    families: FONTS,
                },
            });
        },
    },
    computed: {
        ...mapGetters("storeProps", {
            themeType: "getThemeType",
            themeStyles: "getThemeStyles",
            themeMode: "getThemeMode",
            is_props_loading: "getIsLoading",
            store_settings: "getStoreSettings",
            store_name: "getStoreName",
            bannerURL: "getBannerURL",
        }),
    },
    async mounted() {
        await this.loadFonts();
        await indexedDbService.getDb();
        this.checkIfDeviceIsTouch();
        await this.checkPreviewParams();
        this.setIsLoadingState();

        if (this.store_name) {
            document.title = this.store_name;
        }
    },
};
