<template>
    <div class="select" :class="{ 'select--error': show_error }">
        <span class="select__label">{{ label }} <em class="select__label-marker" v-if="is_required">*</em> </span>
        <div class="select__holder">
            <select class="select__body" :name="name || null" :value="modelValue" @change="onSelectChange">
                <option v-for="[key, value] in Object.entries(options)" :value="key" :key="key">{{ value }}</option>
            </select>
        </div>
        <span class="select__error">{{ error_text }}</span>
    </div>
</template>
<script src="./select.js" />
<style src="./select.scss" scoped lang="scss"></style>
