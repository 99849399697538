export default {
    name: "Select",
    props: {
        modelValue: {
            type: [String, Number],
            default: "",
        },
        label: {
            type: String,
            default: "",
        },
        options: {
            type: Object,
            default: () => ({}),
        },
        name: {
            type: String,
            default: "",
        },
        error_text: {
            type: String,
            default: "",
        },
        is_required: {
            type: Boolean,
            default: false,
        },
        show_error: {
            type: Boolean,
            default: false,
        },
    },

    emits: ["update:modelValue", "onChange"],

    methods: {
        onSelectChange(event) {
            const value = event.target.value;
            this.$emit("update:modelValue", value);
            this.$emit("onChange", value);
        },
    },
};
