import { getProductIndex, getCartProducts, getProducts, getToFavoriteProducts, setProductToCart as setProductToCartService, setToFavoriteProducts } from "@/services/products.service";
import CartService from "@/services/cart.service";
import round from "@/helpers/round_number.js";
import store from "@/store/index.js";

const cartService = new CartService();
const deepCopy = entity => JSON.parse(JSON.stringify(entity));

const state = {
    products: [],
    favoriteProducts: [],
    productsInCart: [],
    filter: {
        order_by: "",
        product_category_ids: [],
        search: "",
    },
    pagination: {
        page: 1,
        per_page: 24,
        item_count: 24,
        item_start: 24,
    },
    isLoading: true,
    shipping: 4.95,
    shipping_mapper: {
        GBP: 4.95,
        USD: 7.95,
        CAD: 12.5,
        EUR: 12.5,
    },
    related_products: [],
    shipping_countries: [],
};

const getters = {
    getProducts(state) {
        return state.products;
    },
    getAvailableProducts(state) {
        return state.productsInCart.filter(item => item.is_available);
    },
    getUnavailableProducts(state) {
        return state.productsInCart.filter(item => !item.is_available);
    },
    getProductsInCartHash(state) {
        return state.productsInCart.reduce((a, v) => ({ ...a, [v.product.id]: v }), {});
    },
    getAvailableProductsTotalCount(state) {
        return state.productsInCart.filter(item => item.is_available).reduce((acc, item) => acc + item.count, 0);
    },
    getUnavailableProductsTotalCount(state) {
        return state.productsInCart.filter(item => !item.is_available).reduce((acc, item) => acc + item.count, 0);
    },
    getAvailableProductsTotalPrice(state) {
        return round(state.productsInCart.filter(item => item.is_available).reduce((acc, item) => acc + item.product.selling_price * item.count, 0));
    },
    getUnavailableProductsTotalPrice(state) {
        return state.productsInCart.filter(item => !item.is_available).reduce((acc, item) => acc + item.product.selling_price * item.count, 0);
    },
    getFavoriteProducts(state) {
        return state.favoriteProducts;
    },
    getProductInCart(state) {
        return state.productsInCart;
    },
    getPagination(state) {
        return state.pagination;
    },
    getIsLoading(state) {
        return state.isLoading;
    },
    getFilterParams(state) {
        return state.filter;
    },
    getShipping(state) {
        const {
            currency: { code },
        } = store.getters["storeProps/getStoreSettings"];
        return state.shipping_mapper[code];
    },
    getRelatedProducts(state) {
        return state.related_products;
    },
    getShippingCountries(state) {
        return state.shipping_countries;
    },
};

const mutations = {
    setProducts(state, payload) {
        state.products = state.pagination.page === 1 ? [...payload] : [...state.products, ...payload];
    },
    setAvailableProducts(state, payload) {
        state.available_products = payload;
    },
    setUnavailableProducts(state, payload) {
        state.unavailable_products = payload;
    },
    setFavoriteProducts(state, payload) {
        state.favoriteProducts = payload;
    },
    setProductsInCart(state, payload) {
        state.productsInCart = [...payload];
    },
    setPagination(state, payload) {
        state.pagination = payload;
    },
    setIsLoading(state, payload) {
        state.isLoading = payload;
    },
    setFilterParams(state, payload) {
        state.filter = {
            ...payload,
        };
    },
    setShipping(state, payload) {
        state.shipping = payload;
    },
    setSubtotalPrice(state, payload) {
        state.subtotal_price = payload;
    },
    setTotalPrice(state, payload) {
        state.total_price = payload;
    },
    setRelatedProducts(state, payload) {
        state.related_products = payload && payload.length ? payload.map(item => ({ ...item, is_in_favorite: false, is_available: true })) : [];
    },
    setShippingCountries(state, payload) {
        state.shipping_countries = payload;
    },
};

const actions = {
    async fetchProductsInCart(context) {
        const products = await getCartProducts();
        context.commit("setProductsInCart", products || []);
    },
    async getProducts(context) {
        context.commit("setIsLoading", true);
        const {
            data: { items, pagination },
        } = await getProducts({
            pagination: state.pagination,
            filter: {
                ...state.filter,
                product_category_ids: state.filter.product_category_ids.join(","),
            },
        });
        context.commit("setPagination", { ...pagination, max_pages: Math.ceil(pagination.item_total / pagination.per_page) });
        context.commit("setProducts", items);
        context.commit("setIsLoading", false);
    },
    async updateFilterParams(context, filter) {
        context.commit("setFilterParams", filter);
        await context.dispatch("updatePaginationPage", 1);
        await context.dispatch("getProducts");
    },
    async setProductToFavorite(context, product) {
        const existedFavoriteProducts = (await getToFavoriteProducts()) || [];
        const handledList = deepCopy([...existedFavoriteProducts, product]);
        await setToFavoriteProducts(handledList);
        context.commit("setFavoriteProducts", handledList);
    },
    async updateFavoriteProducts(context, list = []) {
        const handledList = deepCopy(list);
        await setToFavoriteProducts(handledList);
        context.commit("setFavoriteProducts", handledList);
    },
    async removeProductFromFavorite(context, product_id) {
        const existedFavoriteProducts = (await getToFavoriteProducts()) || [];
        const handledList = deepCopy(existedFavoriteProducts.filter(({ id }) => id !== product_id));
        await setToFavoriteProducts(handledList);
        context.commit("setFavoriteProducts", handledList);
    },
    async fetchFavoriteProducts(context) {
        const favoriteProducts = await getToFavoriteProducts();
        if (favoriteProducts) {
            context.commit("setFavoriteProducts", favoriteProducts || []);
        }
    },
    async setProductToCart(context, { product, count }) {
        const existedProductInCart = (await getCartProducts()) || [];
        const handledList = deepCopy([...existedProductInCart, { product, count: count || 1, is_available: true }]);
        await setProductToCartService(handledList);
        context.commit("setProductsInCart", handledList);
    },
    async removeProductFromCart(context, product) {
        const handledIndex = await getProductIndex(context.state.productsInCart, product);
        const updated_products_in_cart = deepCopy(context.state.productsInCart);
        updated_products_in_cart.splice(handledIndex, 1);
        await setProductToCartService(deepCopy(updated_products_in_cart));
        context.commit("setProductsInCart", updated_products_in_cart);
    },
    async getProductsInCart(context) {
        const products = await getCartProducts();
        context.commit("setProductsInCart", products || []);
    },
    updatePaginationPage(context, payload) {
        context.commit("setPagination", { ...context.state.pagination, page: payload });
    },
    async updateProductInCart(context, { count, product }) {
        const handledIndex = await getProductIndex(context.state.productsInCart, product);
        const updated_products_in_cart = deepCopy(context.state.productsInCart);
        updated_products_in_cart.splice(handledIndex, 1, { count, product, is_available: true });
        await setProductToCartService(deepCopy(updated_products_in_cart));
        context.commit("setProductsInCart", updated_products_in_cart);
    },
    cleanStore(context) {
        context.commit("setPagination", { page: 1, per_page: 24 });
        context.commit("setProducts", []);
    },
    async fetchAvailableProducts(context, check_only_in_stock = false) {
        const existedProductInCart = (await getCartProducts()) || [];

        const params = existedProductInCart.map(item => {
            return {
                id: item.product.id,
                combination: item.product.type !== "simple" ? item.product.combination : null,
                store_product_variant_id: item.product.store_product_variant_id || null,
            };
        });

        const { data } = await cartService.postProducts({ store_products: params });
        const { store_products_available, store_products_unavailable, related_products, shipping_countries } = data || {};

        if (shipping_countries) {
            context.commit("setShippingCountries", shipping_countries);
        }

        const data_store_products_hash = {};
        const data_store_products_hash_unavailable = {};
        // const default_customisation = {
        //     colour: null,
        //     font: null,
        //     text: null,
        //     type: null,
        // };
        if (store_products_unavailable && store_products_unavailable.length) {
            store_products_unavailable.forEach(item => (data_store_products_hash_unavailable[`${item.id}_${item.store_product_variant_id}_${item.combination ? item.combination : null}`] = item));
        }
        store_products_available.forEach(item => (data_store_products_hash[`${item.id}_${item.store_product_variant_id}_${item.combination ? item.combination : null}`] = item));
        const available = [];
        existedProductInCart.forEach(itemBack => {
            const item = itemBack.product;
            const itemFromBackendAvailable = data_store_products_hash[`${item.id}_${item.store_product_variant_id}_${item.combination ? item.combination : null}`];
            const itemFromBackendUnAvailable = data_store_products_hash_unavailable[`${item.id}_${item.store_product_variant_id}_${item.combination ? item.combination : null}`];
            const updated_item = itemFromBackendUnAvailable || itemFromBackendAvailable;
            if (updated_item && updated_item.store_product_image_url) {
                available.push({
                    product: check_only_in_stock
                        ? { ...item }
                        : {
                              ...item,
                              ...updated_item,
                              combination: item.combination,
                              // meta_data: {
                              //     ...item.meta_data,
                              //     customisation: updated_item.is_has_editable_text_area ?
                              //         item.meta_data.customisation :
                              //         default_customisation
                              // },
                              store_product_image_path: updated_item.store_product_image_url,
                              is_has_editable_text_area: item.is_has_editable_text_area,
                              //personalise_state: updated_item.is_has_editable_text_area ? item.personalise_state : false
                          },
                    is_available: !!itemFromBackendAvailable && item.is_has_editable_text_area === updated_item.is_has_editable_text_area,
                    count: itemBack.count,
                });
            }
        });
        //(itemBack.product.is_has_editable_text_area === updated_item.is_has_editable_text_area) &&

        context.commit("setProductsInCart", available);
        context.commit("setRelatedProducts", related_products || []);
        await setProductToCartService(deepCopy(available));
    },
    updateRelatedProducts(context, payload) {
        context.commit("setRelatedProducts", payload || []);
    },
    async clearProductsInCart(context) {
        context.commit("setProductsInCart", []);
        await setProductToCartService(deepCopy([]));
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
    namespaced: true,
};
