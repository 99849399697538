import InputField from "@/components/ui/inputField/inputField.vue";
import Checkbox from "@/components/ui/checkbox/checkbox.vue";
import Select from "@/components/ui/select/select.vue";
import { required, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import { mapGetters } from "vuex";
import ApiService from "@/services/index.js";

const deepCopy = data => JSON.parse(JSON.stringify(data));

const phoneValidation = helpers.regex(/^[\d+ ]*$/);
const emailValidation = helpers.regex(/^[0-9a-zA-Z+_~!#$%&‘./=^'{}|-]+@[a-zA-Z0-9._%+-]+\.[a-zA-Z]{2,}$/);

const apiService = new ApiService();

const TAX_JAR_URL = `${process.env.VUE_APP_API_HOST_NAME}/api/website/checkout/taxjar-taxes`;

function debounce(func, wait = 300) {
    let timeout;
    return function(...args) {
        const context = this;
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(context, args), wait);
    };
}

export default {
    name: "BillingDetails",
    components: { InputField, Checkbox, Select },
    props: {
        billing_details: {
            type: Object,
        },
        shipping_details: {
            type: Object,
        },
        is_deliver_to_different_address: {
            type: Number,
            defaultValue: 0,
        },
        customer_note: {
            type: String,
            defaultValue: "",
        },
    },
    data: () => ({
        billing_details_data: {
            first_name: "",
            last_name: "",
            company: "",
            billing_street: "",
            billing_apartment: "",
            billing_country: "",
            billing_state: "",
            billing_city: "",
            billing_zip_code: "",
            phone: "",
            email: "",
        },
        shipping_details_data: {
            shipping_first_name: "",
            shipping_last_name: "",
            shipping_street: "",
            shipping_apartment: "",
            shipping_country: "",
            shipping_city: "",
            shipping_zip_code: "",
            shipping_state: "",
        },
        shipping_countries_list: {},
        billing_country_code: "",
        shipping_country_code: "",
        billing_state_code: "",
        shipping_state_code: "",
        billing_states: null,
        shipping_states: null,
        billing_fields_names: null,
        shipping_fields_names: null,
        tax_jar_error: null,
        amount_to_collect: null,
    }),
    validations() {
        return {
            billing_details_data: {
                first_name: { required },
                last_name: { required },
                billing_street: { required },
                billing_country: { required },
                billing_state: this.billing_country_code === "US" ? { required } : {},
                billing_city: { required },
                billing_zip_code: { required },
                phone: { required, phoneValidation },
                email: { required, emailValidation },
            },
            shipping_details_data: {
                shipping_first_name: { required },
                shipping_last_name: { required },
                shipping_street: { required },
                shipping_country: { required },
                shipping_state: this.shipping_country_code === "US" ? { required } : {},
                shipping_city: { required },
                shipping_zip_code: { required },
            },
        };
    },
    setup() {
        const v$ = useVuelidate();
        return { v$ };
    },
    methods: {
        async onBillingFieldChange({ value, field, no_trigger }) {
            this.$emit("onBillingDetailsChange", { ...deepCopy(this.billing_details), [field]: value });
            this.billing_details_data[field] = value;

            if (!no_trigger) {
                this.v$.billing_details_data?.[field]?.$touch();
            }
        },
        async onShippingFieldChange({ value, field, no_trigger }) {
            this.$emit("onShippingDetailsChange", { ...deepCopy(this.shipping_details), [field]: value });
            this.shipping_details_data[field] = value;
            if (!no_trigger) {
                this.v$.shipping_details_data?.[field]?.$touch();
            }
        },
        async onCheckboxChange(state) {
            this.tax_jar_error = null;
            this.$emit("onCheckboxChange", Number(state));
            if (state) {
                this.$emit("setupAmount", null);
            }
        },
        validateBillingDetails() {
            this.v$.billing_details_data.$reset();
            this.v$.shipping_details_data.$reset();

            if (this.is_deliver_to_different_address) {
                this.v$.billing_details_data.$touch();
                this.v$.shipping_details_data.$touch();
                return !this.v$.$invalid;
            } else {
                this.v$.billing_details_data.$touch();
                return !this.v$.billing_details_data.$invalid;
            }
        },
        async getTaxJar() {
            if (
                (!this.is_deliver_to_different_address &&
                    this.billing_country_code === "US" &&
                    this.billing_state_code &&
                    this.billing_details_data.billing_zip_code &&
                    this.store_settings.currency.code === "USD") ||
                (this.is_deliver_to_different_address &&
                    this.shipping_country_code === "US" &&
                    this.shipping_state_code &&
                    this.shipping_details_data.shipping_zip_code &&
                    this.store_settings.currency.code === "USD")
            ) {
                this.fetchTaxJarData();
            }
        },
        cleanBillingFields(list) {
            const target_fields = list.reduce((acc, current) => ({ ...acc, [current]: "" }), {});

            const empty_fields = { ...deepCopy(this.billing_details), ...target_fields };
            this.billing_details_data = { ...this.billing_details_data, ...target_fields };
            this.$emit("onBillingDetailsChange", empty_fields);
        },
        cleanShippingFields(list) {
            const target_fields = list.reduce((acc, current) => ({ ...acc, [current]: "" }), {});

            const empty_fields = { ...deepCopy(this.shipping_details), ...target_fields };
            this.shipping_details_data = { ...this.shipping_details_data, ...target_fields };
            this.$emit("onShippingDetailsChange", empty_fields);
        },
        async getBillingCountry(v) {
            this.v$.billing_details_data.billing_state.$touch();
            this.v$.billing_details_data.billing_state.$reset();
            const target_states = this.shipping_countries.find(c => c["alpha-2"] === v)?.states;
            this.billing_country_code = v;
            this.billing_states =
                target_states.length > 0
                    ? target_states.reduce((acc, { abbreviation, name }) => {
                          acc[abbreviation] = name;
                          return acc;
                      }, {})
                    : null;
            this.billing_fields_names = this.shipping_countries.find(c => c["alpha-2"] === v)?.shipping_field_names;
            await this.onBillingFieldChange({ value: this.shipping_countries_list[v], field: "billing_country" });

            this.cleanBillingFields(["billing_state", "billing_city", "billing_zip_code", "billing_street", "billing_apartment"]);
            this.billing_details_data.billing_state = "";
            this.tax_jar_error = null;
            this.amount_to_collect = null;
            this.$emit("setupAmount", null);
        },
        async onBillingZipCodeInput(value) {
            this.tax_jar_error = null;
            this.onBillingFieldChange({ value, field: "billing_zip_code" });
            await this.getTaxJar();
        },
        async onShippingZipCodeInput(value) {
            this.tax_jar_error = null;
            this.onShippingFieldChange({ value, field: "shipping_zip_code" });
            await this.getTaxJar();
        },
        async getShippingCountry(v) {
            const target_states = this.shipping_countries.find(c => c["alpha-2"] === v)?.states;
            this.shipping_country_code = v;
            this.shipping_states =
                target_states.length > 0
                    ? target_states.reduce((acc, { abbreviation, name }) => {
                          acc[abbreviation] = name;
                          return acc;
                      }, {})
                    : null;
            this.shipping_fields_names = this.shipping_countries.find(c => c["alpha-2"] === v)?.shipping_field_names;

            await this.onShippingFieldChange({ value: this.shipping_countries_list[v], field: "shipping_country" });

            this.cleanShippingFields(["shipping_state", "shipping_city", "shipping_zip_code", "shipping_street", "shipping_apartment"]);

            this.shipping_details_data.shipping_state = "";
            this.tax_jar_error = null;
            this.amount_to_collect = null;
            this.$emit("setupAmount", null);
        },
        async getBillingStates(v) {
            this.billing_state_code = v;
            this.billing_details_data.billing_state = this.billing_states[v];
            await this.onBillingFieldChange({ value: this.billing_states[v], field: "billing_state" });

            this.cleanBillingFields(["billing_city", "billing_zip_code", "billing_street", "billing_apartment"]);
            this.v$.billing_details_data.billing_state.$reset();
            this.$emit("setupAmount", null);
        },
        async getShippingStates(v) {
            this.shipping_state_code = v;
            await this.onShippingFieldChange({ value: this.shipping_states[v], field: "shipping_state" });
            this.cleanShippingFields(["shipping_zip_code", "shipping_street", "shipping_apartment", "shipping_city"]);
            this.v$.shipping_details_data?.shipping_state?.$reset();
            this.$emit("setupAmount", null);
        },
    },
    created() {
        // Создаём обёрнутую debounce функцию один раз
        this.fetchTaxJarData = debounce(async () => {
            const taxjar_data = await apiService.post({
                url: TAX_JAR_URL,
                params: {
                    to_country: "US",
                    to_state: this.is_deliver_to_different_address ? this.shipping_state_code : this.billing_state_code,
                    to_zip: this.is_deliver_to_different_address ? this.shipping_details_data.shipping_zip_code : this.billing_details_data.billing_zip_code,
                    shipping: this.shipping,
                    amount: this.subtotal_price,
                    line_items: this.available_products.map(({ product, count }) => ({ product_tax_code: product.taxjar_product_tax_code, unit_price: product.selling_price, quantity: count })),
                },
            });
            if (taxjar_data.error) {
                this.tax_jar_error = taxjar_data.error?.description;
                this.$emit("setupAmount", null);
            } else {
                this.amount_to_collect = taxjar_data.amount_to_collect;
                this.$emit("setupAmount", this.amount_to_collect);
            }
        }, 500);
    },
    emits: {
        onBillingDetailsChange: null,
        onShippingDetailsChange: null,
        onChangeCustomerNote: null,
        onCheckboxChange: null,
        validationCallback: null,
        setupAmount: null,
    },
    computed: {
        ...mapGetters("products", {
            shipping_countries: "getShippingCountries",
            subtotal_price: "getAvailableProductsTotalPrice",
            shipping: "getShipping",
            available_products: "getAvailableProducts",
        }),
        ...mapGetters("storeProps", { store_settings: "getStoreSettings" }),
    },
    watch: {
        is_deliver_to_different_address() {
            this.getTaxJar();
        },
    },
    mounted() {
        this.billing_details_data = this.billing_details;
        this.shipping_details_data = this.shipping_details;
        this.$emit("validationCallback", this.validateBillingDetails);
        this.shipping_countries_list = (this.shipping_countries || []).reduce((acc, current) => {
            acc[current["alpha-2"]] = current.country;
            return acc;
        }, {});
    },
};
